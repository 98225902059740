// import React, { useRef, useEffect } from "react";
import React from "react";
import CleanLayout from "layouts/Clean";
import { SEO, Subtitle, Text, FoldingTeam, Intro } from "components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery, Link } from "gatsby";

const pageProperties = {
  title: "Lavori che ci piace raccontare",
  description:
    "I nostri clienti sono il nostro valore, per questo quando accettiamo un lavoro ci mettiamo l'anima e il cuoree. Ma ora scopri quello che sappiamo fare",
};

function BlogPage() {
  const {
    allContentfulPortfolio: { nodes },
  } = useStaticQuery(GET_WORKS);
  return (
    <CleanLayout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Clienti e lavori. Il nostro portfolio."
        description="Scopri come lavoriamo direttmente dalle case history dei clienti!"
      />

      {/* <div className="w-full md:mt-32" ref={ref}> */}
      <div className="w-screen px-8 md:px-28">
        <Intro {...pageProperties} />
        <section className="mb-12">
          <div className="grid grid-cols-1 md:grid-cols-3 grid grid-flow-row gap-8">
            {nodes.map(
              ({ customer, name, preview, slug, title, subtitle }, index) => {
                const image = getImage(preview);
                const logo = getImage(customer?.logo);
                return (
                  <Link key={index} href={`/clienti/${slug}`}>
                    <a>
                      <div className="group relative overflow-hidden h-72 flex items-end justify-start shadow-button transition-all duration-700 hover:shadow-buttonHover focus:shadow-buttonActive rounded border-4 border-black">
                        <div className="absolute flex justify-center items-center overflow-hidden z-10 h-full">
                          <GatsbyImage
                            objectFit="cover"
                            image={image}
                            className=" flex-shrink-0 w-full h-full w-auto shadow-portfolio"
                            alt={`${name} portfolio`}
                          />
                        </div>

                        <div className="relative z-30 pb-6 pl-6 pr-4 flex flex-col">
                          <div className="relative z-20 w-full h-full pb-8 opacity-0 flex items-bottom justify-start group-hover:opacity-100 group-hover:transform group-hover:translate-y-8 transition-all duration-1000 ease-in-out">
                            <GatsbyImage
                              objectFit="contain"
                              image={logo}
                              className="h-20 w-20"
                              alt={`${name} portfolio`}
                            />
                          </div>

                          <div className="opacity-0 group-hover:opacity-100 transition duration-700 flex my-1">
                            <Text size="smallest" color="white">
                              {subtitle}
                            </Text>
                          </div>
                          <Subtitle big white bolder>
                            {title}
                          </Subtitle>
                        </div>
                      </div>
                    </a>
                  </Link>
                );
              }
            )}
          </div>
        </section>
      </div>
    </CleanLayout>
  );
}

const GET_WORKS = graphql`
  query GetWorks {
    allContentfulPortfolio(filter: { node_locale: { eq: "it" } }) {
      nodes {
        customer {
          name
          logo {
            gatsbyImageData
          }
        }
        name
        preview {
          gatsbyImageData
        }
        slug
        title
        subtitle
      }
    }
  }
`;

export default BlogPage;
